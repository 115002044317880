import React from 'react'

const Index = ({ children, className }) => {
    return (
        <div className='prose mx-none'>
            <div className='w-full'>
                <p className={`${className} text-ox-text dark:text-ox-dark-text p-0 m-0 leading-6`}>
                    {children}
                </p>
            </div>
        </div>
    )
}

export default Index