import { useState } from 'react';
import { Button, Input, Textarea } from '@nextui-org/react';
import { enqueueSnackbar } from 'notistack';
import { getError } from '@/utils/error';
import axios from 'axios';
import React from 'react';
import { MdLocationOn } from 'react-icons/md';
import { useSession } from 'next-auth/react';
import Reveal from './Reveal';
import { HiMail } from 'react-icons/hi';
import Heading from '@/custom/Heading'
import { AiOutlineMail, AiOutlineMessage, AiOutlineUser } from 'react-icons/ai';

const Contact = () => {
  const { data: session } = useSession();

  const [profile, setProfile] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleCancel = () => {
    setProfile({
      name: '',
      email: '',
      message: '',
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!session?.user?.name) return window.location.href = "/login";

    if (!profile.email) return enqueueSnackbar("Please enter email", {
      variant: 'default',
    });

    if (!profile?.message) return enqueueSnackbar("Please enter message", {
      variant: 'default',
    });

    try {
      const result = await axios.put('/api/contact', {
        name: profile.name || session?.user?.name,
        email: profile.email || session?.user?.email,
        message: profile.message || session?.user?.message,
      });
      if (result.error) {
        enqueueSnackbar(result.error, {
          variant: 'default',
        });
      } else {
        enqueueSnackbar('Message send.', {
          variant: 'default',
        });
      }
    } catch (err) {
      enqueueSnackbar(getError(err), {
        variant: 'default',
      });
    }
    setProfile({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <div className="prose max-w-none">
      <div id='contact-form' className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="w-full p-5 h-full flex flex-col justify-center items-center">
          <div className="text-center w-full flex items-center justify-center">
            <Reveal>
              <Heading>
                Contact Us
              </Heading>
            </Reveal>
          </div>
          <div className="flex md:flex-row flex-col gap-10 items-start mt-10">
            <div>
              <p className="dark:text-ox-dark-text m-0">
                If you require assistance, please reach out to us; we will be
                available to you 24 hours a day, seven days a week. We adore our
                students more than anyone else on the planet.
              </p>

              <div className='flex flex-col gap-4 mt-5'>
                <div className="flex items-start gap-3">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-ox-black dark:bg-ox-blue text-white">
                    <MdLocationOn size={20} />
                  </div>
                  <div className='-mt-1.5'>
                    <h3 className="m-0 text-ox-black dark:text-ox-dark-text">
                      Our Address
                    </h3>
                    <p className="m-0 text-sm text-ox-text-light dark:text-ox-dark-text-gray">
                      Dhaka, Bangladesh
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-ox-black dark:bg-ox-blue text-white">
                    <HiMail size={20} />
                  </div>
                  <div className='-mt-1.5'>
                    <h3 className="m-0 text-ox-black dark:text-ox-dark-text">
                      Contact
                    </h3>
                    <p className="m-0 text-sm leading-6 text-ox-text-light dark:text-ox-dark-text-gray">
                      oxfordpte.care@gmail.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-fit w-full md:max-w-6xl">
              <h3 className="mb-4 m-0 text-ox-black dark:text-ox-dark-text">Ready to Get Started?</h3>
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-4">
                  <div>
                    <Input
                      label="Username"
                      labelPlacement="outside"
                      placeholder="Enter username"
                      variant='bordered'
                      radius='none'
                      startContent={
                        <AiOutlineUser className="text-xl text-default-400 pointer-events-none flex-shrink-0" />
                      }
                      type="text"
                      name="name"
                      value={profile.name}
                      onChange={(e) =>
                        setProfile({ ...profile, name: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <Input
                      label="Email"
                      labelPlacement="outside"
                      placeholder="Enter email address"
                      variant='bordered'
                      radius='none'
                      startContent={
                        <AiOutlineMail className="text-xl text-default-400 pointer-events-none flex-shrink-0" />
                      }
                      type="email"
                      name="email"
                      value={profile.email}
                      onChange={(e) =>
                        setProfile({ ...profile, email: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <Textarea
                      label="Message"
                      labelPlacement="outside"
                      placeholder="Enter your message"
                      variant='bordered'
                      radius='none'
                      type="text"
                      name="message"
                      value={profile.message}
                      onChange={(e) =>
                        setProfile({ ...profile, message: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="flex gap-2 mt-4">
                  {
                    profile?.name || profile.message || profile.email ?
                      <Button onClick={handleCancel} size='md' color='danger'>Cancel</Button> : null
                  }
                  <Button
                    type="submit"
                    size='md'
                    color='primary'
                  >
                    Send Message
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
