import { Button } from '@nextui-org/react';
import Image from 'next/image';
import { useRouter } from 'next/router';

export default function Hero() {
    const { push } = useRouter()
    return (
        <section className="bg-ox-hero-bg dark:bg-blue-500/20 relative overflow-hidden w-full">
            <Image src="/herobg.jpg" alt="hero-bg" className="absolute dark:hidden -top-[1rem] left-1/2 -ml-[40rem] w-[163.125rem] max-w-none sm:-ml-[67.5rem]" width={100} height={100} />
            <div className="absolute inset-0 bg-[url('https://play.tailwindcss.com/img/grid.svg')] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"></div>
            <div className="container mx-auto px-4 py-16 relative">
                <div className="dark:text-foreground p-8 md:p-12 lg:p-16 text-center mx-auto">
                    <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold leading-10 mb-6">Unlock Your English Potential</h1>
                    <p className="text-ox-text-light dark:text-foreground text-lg sm:text-xl max-w-2xl mx-auto leading-7 mb-8">Enhance your English proficiency and achieve success with our carefully curated collection of online English test videos.</p>
                    <Button onClick={() => push('/login')} variant='solid' color='primary' radius='full' size='lg'>Start Learning</Button>
                </div>
            </div>
        </section>
    )
}
