import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { getError } from '@/utils/error';
import axios from 'axios';
import { Input, Button } from '@nextui-org/react';
import Paragraph from '@/custom/Paragraph'
import Heading from '@/custom/Heading'
import { AiOutlineMail } from 'react-icons/ai';

const Newsletter = () => {
  const [newsletter, setNewsletter] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newsletter) return enqueueSnackbar('Please enter email address', {
      variant: 'default',
    });

    try {
      const result = await axios.put('/api/newsletter', {
        email: newsletter,
      });
      if (result.error) {
        enqueueSnackbar(result.error, {
          variant: 'default',
        });
      } else {
        enqueueSnackbar('You have subscribed to our newsleter.', {
          variant: 'default',
        });
      }
    } catch (err) {
      enqueueSnackbar(getError(err), {
        variant: 'default',
      });
    }
    setNewsletter('');
  };
  return (
    <div className='prose max-w-none bg-blue-500/20'>
      <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center">
        <div className='bg-white shadow rounded-full p-2.5 w-fit'>
          <svg width="40px" height="40px" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" color="#2d2f3a"><g clipPath="url(#send-diagonal_svg__clip0_2476_13290)" stroke="#2d2f3a" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><path d="M22.152 3.553L11.178 21.004l-1.67-8.596L2 7.898l20.152-4.345zM9.456 12.444l12.696-8.89"></path></g><defs><clipPath id="send-diagonal_svg__clip0_2476_13290"><path fill="#fff" d="M0 0h24v24H0z"></path></clipPath></defs></svg>
        </div>
        <div>
          <Heading className='py-4 max-w-xl text-center'>
            Subscribe to our newsletter
          </Heading>
          <Paragraph className='text-ox-text-light max-w-xl text-center leading-6'>Get up-to-date on latest new materials and features, guides, and case studies. No Spam. Helpful articles to improve your skills. Sent directly to your inbox.</Paragraph>
          <form onSubmit={handleSubmit} className="flex items-center w-full">
            <div className="flex w-full gap-2 mt-4">
              <Input
                size='lg'
                variant='flat'
                radius='none'
                placeholder="Enter your email"
                type="text"
                name="newsletter"
                value={newsletter}
                onChange={(e) => setNewsletter(e.target.value)} 
                startContent={
                  <AiOutlineMail size={20} className="text-default-400 pointer-events-none flex-shrink-0 px-0 m-0" />
                }
                className='text-foreground bg-white'
                />
              <Button type='submit' radius='none' size='lg' variant='faded' className='m-0 p-0'>Subscribe</Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Newsletter