import React from 'react'
import { Card, Image } from "@nextui-org/react";
import Button from "@/custom/Button"
import { useDispatch } from 'react-redux';
import { addToCart } from '@/services/cart';
import VideoBanner from './VideoBanner';

function CheckIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-3 w-3"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
            />
        </svg>
    );
}

const CardComponent = ({ id, item, price, video }) => {
    const dispatch = useDispatch();

    const handleToCart = (id, item, price) => {
        dispatch(addToCart({ id, item, price }));
    };


    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    // {months[new Date().getMonth()]} Speaking Predictions.
    return (
        <Card className='w-full sm:w-56 shadow-none border rounded-none'>
            <VideoBanner video="/assets/readaloud.mp4"/>
            <div className="flex flex-col gap-2 p-4 w-full">
                <h4 className='m-0 text-start dark:text-foreground'>The complete bootcamp of something.</h4>
                <div className="flex items-center space-x-1 mb-1">
                    <span className='text-sm font-semibold text-yellow-800'>4.0</span>
                    <svg className="w-4 h-4 text-yellow-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg className="w-4 h-4 text-yellow-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg className="w-4 h-4 text-yellow-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg className="w-4 h-4 text-yellow-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg className="w-4 h-4 text-gray-300 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                </div>

                <li className="flex items-center gap-1 m-0">
                    <CheckIcon size={5} />
                    <span className="text-xs m-0 text-ox-light-gray dark:text-foreground">5 team members</span>
                </li>
                <li className="flex items-center gap-1 m-0">
                    <CheckIcon size={5} />
                    <span className="text-xs m-0 text-ox-light-gray dark:text-foreground">200+ components</span>
                </li>
                <li className="flex items-center gap-1 m-0">
                    <CheckIcon size={5} />
                    <span className="text-xs m-0 text-ox-light-gray dark:text-foreground">40+ built-in pages</span>
                </li>
                <li className="flex items-center gap-1 m-0">
                    <CheckIcon size={5} />
                    <span className="text-xs m-0 text-ox-light-gray dark:text-foreground">1 year free updates</span>
                </li>
                <li className="flex items-center gap-1 m-0">
                    <CheckIcon size={5} />
                    <span className="text-xs m-0 text-ox-light-gray dark:text-foreground">
                        Life time technical support
                    </span>
                </li>
                <div className='flex justify-between items-center mt-2 w-full'>
                    <div className='flex gap-2 items-center'>
                        <p className='text-ox-black m-0 font-semibold dark:text-foreground'>$5</p>
                        <span className='text-ox-text-light line-through dark:text-foreground'>$3</span>
                    </div>
                    <Button
                        size="sm"
                        onClick={() => handleToCart(id, item, price)}>
                        Add to cart
                    </Button>
                </div>
            </div>
        </Card>
    )
}

export default CardComponent